<template>
  <b-modal ref="singInModal" id="singInModal" title="SignInModal">
    <template v-slot:modal-header-close>
      <b-button class="modal-close" @click="closeSignInModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            d="M11.033,88l4.549-4.549a1.43,1.43,0,0,0,0-2.022L14.57,80.419a1.43,1.43,0,0,0-2.022,0L8,84.967,3.451,80.419a1.43,1.43,0,0,0-2.022,0L.419,81.43a1.43,1.43,0,0,0,0,2.022L4.967,88,.419,92.549a1.43,1.43,0,0,0,0,2.022L1.43,95.581a1.43,1.43,0,0,0,2.022,0L8,91.033l4.549,4.549a1.43,1.43,0,0,0,2.022,0l1.011-1.011a1.43,1.43,0,0,0,0-2.022Z"
            transform="translate(0 -80)"
            fill="#878787"
          />
        </svg>
      </b-button>
    </template>

    <template v-slot:modal-title>
      <span :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("signin") }}</span>
    </template>

    <template v-slot:default>
      <form method="POST" @submit.prevent="submitAccessCode">
        <label for="accessCode" :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("signin.title") }}</label>
        <div v-if="errors.length">
          <ul>
            <li v-for="error in errors" :key="error.id">
              <span style="color: red" :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ error.message }}</span>
            </li>
          </ul>
        </div>

        <input
          type="tel"
          id="accessCode"
          name="access-code"
          v-model="accessCode"
          :placeholder="$t('input.accesscode')"
          pattern="[0-9]*"
          inputmode="numeric" :class="[currentLanguage == 'AR' ? 'rtlText': '']"
        />
        <div class="form-button">
          <button type="submit" class="signin-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.015"
              height="15.325"
              viewBox="0 0 20.015 15.325"
            >
              <path
                d="M16.262,79.011H12.978a.47.47,0,0,1-.469-.469V76.978a.47.47,0,0,1,.469-.469h3.284a1.25,1.25,0,0,0,1.251-1.251V67.753A1.25,1.25,0,0,0,16.262,66.5H12.978a.47.47,0,0,1-.469-.469V64.469A.47.47,0,0,1,12.978,64h3.284a3.754,3.754,0,0,1,3.753,3.753v7.506A3.754,3.754,0,0,1,16.262,79.011Zm-1.837-7.857L7.857,64.586a.94.94,0,0,0-1.6.665V69H.938A.936.936,0,0,0,0,69.942v3.753a.936.936,0,0,0,.938.938H6.255v3.753a.94.94,0,0,0,1.6.665l6.567-6.567A.946.946,0,0,0,14.425,71.154Z"
                transform="translate(0 -64)"
              />
            </svg>
            <span :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("signin") }}</span>
          </button>
        </div>
      </form>
    </template>

   <template v-slot:modal-footer v-if="(this.currentCountry != 'UK' && this.currentCountry != 'LT' && this.currentCountry != 'EE' && !(this.currentCountry == 'CZ' && helpers.portal_name == 'Fizzzplay')) || (this.currentCountry == 'LT' && helpers.portal_name == 'Fizzzplay')">
      <div class="registration-text">
        <h4 :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("register.headline") }}</h4>
        <a :href="landingPage" class="registration_link" :class="[currentLanguage == 'AR' ? 'rtlText': '']">&gt;&gt; {{ $t("register.link") }} &lt;&lt;</a>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios';
import EventBus from '../helper/event_bus';
import { helpers } from '../helper/portal_config';

export default {
  name: 'SignInModal',
  data() {
    return {
      accessCode: '',
      link: '',
      errors: [],
      helpers: '',
      currentLanguage: "",
      currentCountry: ""
    };
  },
  computed: {
      landingPage: function() {
          this.setCurrentCountry();
          let country = this.currentCountry;
          return helpers.landingUrl[0][country];
      }
  },
  methods: {
    closeSignInModal() {
      this.errors = [];
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
    checkForm() {
      this.errors = [];
      if (this.accessCode && this.accessCode.length == 6) {
        // access code was valid
        return true;
      }
      const errorObj = { id: 1, message: this.$i18n.t('signin.error') };
      this.errors.push(errorObj);
      return false;
    },
    getMessageForErrorCode(errorCode) {
      this.errors = [];
      const langErrorMsg = this.$i18n.t(`error.E${errorCode}`);
      if (langErrorMsg) {
        this.errorMsg = langErrorMsg;
      } else {
        // not found error message we can print out the default one
        this.errorMsg = this.$i18n.t('error.default');
      }
      const errorObj = {
        id: 222,
        message: this.errorMsg,
      };
      this.errors.push(errorObj);
    },
    submitAccessCode() {
      // need to check form access before send it
      if (!this.checkForm()) return false;
      axios
        .get(
          `https://api.hubdelivery.io/store/checkAccessCode?portal_name=${
            helpers.portal_name
          }&country=${this.currentCountry}&language=${this.currentLanguage}&access_code=${
            this.accessCode
          }`,
        )
        .then((response) => {
          if (response.data.treatment == 1) {
            this.$store
              .dispatch('globals/login', {
                accessCode: this.accessCode,
              })
              .then(() => {
                this.$refs.singInModal.hide();
                this.closeSignInModal();
                if (this.link) {
                  this.$router.push(this.link);
                }
              });
          }
        })
        .catch((error) => {
          this.getMessageForErrorCode(error.response.data.error_code);
        });
    },
  },
  mounted() {
    this.helpers = helpers;
    this.setCurrentLanguage();
    this.setCurrentCountry();
    EventBus.$on('SHOW_SIGNIN_MODAL', ($link) => {
      this.link = $link;
      setTimeout(() => {
        this.$refs.singInModal.show();
      }, 200);
    });
  },
};
</script>

<style>
.modal.show {
  display: block;
}
</style>
